.contact-box {
  position: relative;
  height: 48px;
  padding-left: 45px;
  border-radius: 7px;
  border: solid 1px #eae5e1;
  background-color: #f6f4f1;
  font-size: 15px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #4264fd;

  & + & {
    margin-top: 10px;
  }

  &::before {
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    top: 50%;
    left: 15px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    transform: translateY(-50%);
  }

  &--with-arrow {
    &::after {
      background-image: url('/static/images/breadcrumbs-icon.svg');
      content: '';
      width: 10px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      margin: auto;
    }
  }

  &--no-text {
    &::after {
      background-image: url('/static/images/breadcrumbs-icon.svg');
      content: '';
      width: 10px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      margin: auto;
    }
  }

  &--phone {
    &::before {
      background-image: url('/static/images/phone_call_blue-icon.svg');
    }
  }

  &--email,
  &--website {
    padding: 10px 15px;
    height: 38px;
    border-radius: 20px;
    display: inline-block;
    text-decoration: underline;
    margin-right: 10px;
  }

  &--website {
    &--facebook {
      padding-left: 40px;

      &::before {
        background-image: url('/static/images/social/item-icons/facebook.svg');
      }
    }

    &--instagram {
      padding-left: 40px;

      &::before {
        width: 24px;
        height: 24px;
        background-image: url('/static/images/social/item-icons/instagram.svg');
      }
    }

    &--youtube {
      &::before {
        width: 24px;
        height: 18px;
        background-image: url('/static/images/social/item-icons/youtube.svg');
      }
    }
  }

  &--schedule {
    &::before {
      background-image: url('/static/images/schedule-clock-icon.svg');
    }
  }

  &--brochure {
    &::before {
      background-image: url('/static/images/icon-download.svg');
    }
  }

  &__value {
    position: relative;
  }

  &__text {
    color: #3c5efd;
    margin-right: 15px;
    text-decoration: underline;
  }
}
