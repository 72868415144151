$search-popup-header-size: 55px;
$search-popup-header-size-vw: get-vw($search-popup-header-size);

.search-popup {
  &--filters:not(#{&}--is-focused) {
    background-color: $colour-grey-desert-storm;
  }

  &__header {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: $search-popup-header-size-vw;
    z-index: 99;
    // stylelint-disable-next-line
    -webkit-backface-visibility: hidden;

    @include mq(480) {
      height: $search-popup-header-size;
    }

    &--brown {
      color: $colour-white;
      background-color: $colour-dark-tan;
    }

    &-title {
      flex: 0 0 33.33%;
      margin: 0;
      font-size: get-vw(16px, 375);
      font-weight: 500;
      text-align: center;

      @include mq(480) {
        font-size: 16px;
      }
    }

    &-action {
      flex: 0 0 33.33%;
      flex-shrink: 1;
      position: relative;
      height: 100%;
    }

    &-btn {
      vertical-align: middle;
      height: 100%;
      padding: 0 get-vw(15px, 375);
      white-space: nowrap;
      background: transparent;
      text-align: left;
      font-family: $helvetica;
      color: $colour-white;
      font-size: get-vw(15px, 375);
      font-weight: 500;

      @include mq(480) {
        padding: 0 15px;
        font-size: 15px;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &--close {
        width: $search-popup-header-size-vw;
        height: $search-popup-header-size-vw;
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 0;
        color: transparent;
        background-image: url($base64-close);
        background-repeat: no-repeat;
        background-position: left 15px center;
        background-size: get-vw(15px, 375);
      }
    }

    @include mq(480) {
      height: $search-popup-header-size;

      &-title {
        font-size: 16px;
      }

      &-btn {
        padding: 0 15px;

        &_text {
          font-size: 15px;
        }

        &--close {
          width: $search-popup-header-size;
          height: $search-popup-header-size;
          padding: 0;
          background-size: 15px;
        }
      }
    }
  }

  &__content {
    padding-top: $search-popup-header-size-vw;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    @include mq(480) {
      padding-top: $search-popup-header-size;
    }

    .is-hidden + & {
      padding-top: 0;
    }

    &-link,
    &-btn {
      display: inline-block;
      padding: 10px 0 10px 10px;
      font-size: 15px;
      color: $colour-neon-blue;
      text-decoration: none;
      background-color: transparent;
    }
  }

  &__container {
    &--locations {
      padding-bottom: 75px;
    }
  }

  &__tools {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid $colour-white-smoke;
    background-color: $colour-grey-desert-storm;

    &:not(#{&}--is-focused) {
      .search-popup--locations & {
        border-bottom: 0;
      }
    }
  }

  &__top-tools {
    display: block;

    &-container {
      outline: none;
      background-color: $colour-grey-desert-storm;
      border-bottom: 1px solid $colour-white-smoke;
    }

    .search-form__city {
      height: 47px;
      margin-bottom: 0;
    }
  }

  &__apply-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: calc(100% - 30px);
    margin: 0 15px;
    bottom: calc(env(safe-area-inset-bottom) + 10px);
    height: 45px;
    border-radius: 7px;
    background-color: $colour-yellow;
    font-size: 16px;
    color: $colour-cocoa-brown;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
    transition: opacity 0.3s ease-in-out 0.3s;

    &__hidden {
      opacity: 0;
      visibility: hidden;
      transition-delay: 0s;
    }
  }
}
