@mixin item-card($width, $marginRight, $el) {
  .item-card {
    width: $width;
    margin-right: $marginRight;

    &:nth-of-type(#{$el}n + #{$el}) {
      margin-right: 0;
    }
  }
}

@mixin items-list {
  position: relative;
  font-size: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

@mixin clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* stylelint-disable */
@mixin multiline-ellipsis(
  $font-size: 12px,
  $line-height: 1.2,
  $lines-to-show: 2
) {
  margin: 0;
  padding: 0;
  display: block; // Fallback for non-webkit
  display: -webkit-box;
  max-height: $font-size * $line-height * $lines-to-show; // Fallback for non-webkit
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* stylelint-enable */

@mixin mq($size, $type: 'min-width', $orientation: '') {
  @if ($orientation != '') {
    @media only screen and (#{$type}: #{$size}px) and (orientation: $orientation) {
      @content;
    }
  } @else {
    @media only screen and (#{$type}: #{$size}px) {
      @content;
    }
  }
}

@mixin arrow(
  $size,
  $fill,
  $bw: 1px,
  $angle: -45deg,
  $side: 'after',
  $indent: $size / 2,
  $indent-type: 'left'
) {
  &::#{$side} {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    border-right: $bw solid $fill;
    border-bottom: $bw solid $fill;
    width: $size;
    height: $size;
    margin-#{$indent-type}: $indent;
    transform: rotate($angle);
    @content;
  }
}
