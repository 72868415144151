.suggestion {
  border-top: 1px solid #f6f4f2;

  &:first-child {
    border-top: 0;
  }

  &-list {
    padding: 0 15px;
  }

  &__link {
    display: block;
    padding: 15px 0;
    font-size: 15px;
    color: $colour-cocoa-brown;
    text-decoration: none;
  }
}
