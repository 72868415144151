.checkbox-wrapper {
  input[type='checkbox'] {
    --active: #a4243b;
    --active-inner: #fff;
    --focus: 2px #a4243b;
    --border: #bbc1e1;
    --border-hover: #a4243b;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    appearance: none;
    appearance: none;
    height: 20px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid #a4243b;
    background: var(--b, var(--background));

    &::after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
    }

    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }

    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
    }

    &:not(.switch) {
      width: 20px;
    }

    &:not(.switch) {
      border-radius: 7px;
    }

    &:not(.switch)::after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 3px;
      transform: rotate(var(--r, 20deg));
    }

    &:not(.switch):checked {
      --r: 43deg;
    }
  }
}

.checkbox-wrapper * {
  box-sizing: inherit;
}

.checkbox-wrapper *::before,
.checkbox-wrapper *::after {
  box-sizing: inherit;
}
