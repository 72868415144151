.m-nav {
  position: relative;
  height: 100%;
  min-height: get-vw(550px);
  box-sizing: border-box;

  &__link {
    display: block;
    width: 100%;
    height: 55px;
    padding: 19px 15px;
    text-align: left;
    border-bottom: 1px solid #eae5e1;
    background-color: #fff;
    color: #2f1f19;
    font-size: 15px;
    font-weight: 400;
    font-family: $helvetica;

    &-top {
      border-top: 1px solid #eae5e1;
      margin: 20px 0;
    }

    &-bottom {
      margin-top: 20px;
      border-top: 1px solid #eae5e1;

      &-transparent {
        background-color: transparent;
        border-bottom: 0;
      }
    }
  }

  &__list {
    padding: 0;
    margin-top: 20px;

    ul li:nth-child(2) {
      border-top: 1px solid #eae5e1;
    }

    ul a {
      text-decoration: none;
    }
  }
}

@media only screen and (min-width: 480px) {
  .m-nav {
    min-height: 550px;

    &__list {
      padding: 16px 0;
    }

    &__link {
      font-size: 14px;
      padding: 10px 15px;
    }
  }
}
