.management-section {
  display: flex;
  gap: 10px;
  font-family: $helvetica;
  font-size: 15px;

  a {
    text-decoration: none;
    text-underline: none;
  }

  &__edit {
    width: 100%;
    padding: 15px;
    height: 50px;
    color: #4264fd;
    text-align: center;
    border-radius: 7px;
    background-color: #dae8ff;
  }

  &__remove {
    width: 100%;
    height: 50px;
    padding: 15px;
    color: #ff586d;
    text-align: center;
    border-radius: 7px;
    background-color: #ffe6e5;
  }
}
