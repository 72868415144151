.contacts-item {
  display: flex;
  width: 100%;
  gap: 10px;
  height: 55px;
  align-items: center;
  border-bottom: 1px solid #eae5e1;
  text-decoration: none;

  &__icon {
    display: inline-block;
    width: 35px;
    text-align: center;
  }

  &__title {
    color: #2f1f19;
    font-size: 15px;
    font-weight: 400;
  }
}
