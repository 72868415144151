.section {
  &-list {
    @include items-list;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0 10px;

    &.vip-page-list {
      padding: 10px;
    }

    &--horizontal-scroll {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 10px 3px;
      overflow-x: scroll;
      position: relative;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none; // hides scrollbar on firefox
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    &-bookmarks {
      justify-content: flex-start;
      margin: 0 auto;
      padding: 0 10px 20px;
      box-sizing: border-box;

      &__empty {
        width: 100%;
        padding: 145px 40px 112px;
        font-size: 18px;
        text-align: center;
        color: #483b36;
        position: relative;

        &::before {
          background-image: url('/static/images/favorites.svg');
          content: '';
          width: 81px;
          height: 75px;
          position: absolute;
          top: 50px;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }
}
