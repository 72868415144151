.bottom-view {
  position: relative;
  overflow: hidden;
  width: 100%;

  &__content {
    padding: 0 15px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #eae5e1;
    position: relative;

    &-title {
      color: #2f1f19;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0 auto;
      padding: 0 40px;
    }
  }

  &__close-btn {
    position: absolute;
    right: 0;
    margin: 0 15px;
  }
}
