.f-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin-bottom: 13px;
  padding: 0 10px;
  border-radius: 3px;
  border: 1px solid $colour-grey-wild-sand;
  background-color: $colour-white;

  &__field {
    display: none;

    &:checked,
    &.fake-checked {
      ~ span {
        background-image: url($base64-tickmark);
        background-color: $colour-neon-blue;
        border-color: $colour-neon-blue;
      }
    }
  }

  &__label {
    font-size: 15px;
    color: $colour-cocoa-brown;
  }

  &__tickmark {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid $colour-white-smoke;
    background-color: $colour-white;
    background-repeat: no-repeat;
    background-position: center;
  }
}
