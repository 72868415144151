.alert-modal {
  &-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    font-family: $helvetica;
    border-bottom: 1px solid #eae5e1;

    &__title {
      color: #2f1f19;
      font-size: 16px;
      font-weight: 500;
    }

    &__message {
      color: #98918a;
      font-size: 16px;
    }
  }

  &-btns {
    display: flex;
    justify-content: center;

    &__btn {
      text-align: center;
      padding-top: 15px;
      width: 100%;
      height: 48px;

      &--decline {
        border-right: 1px solid #eae5e1;
        color: #98918a;
      }

      &--red {
        color: #ff586d;
      }

      &--blue {
        color: #4264fd;
      }
    }
  }
}
