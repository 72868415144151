.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-decoration: none;

  &-tools {
    height: 40px;
    line-height: 38px;
    padding: 0 10px;
    color: #4264fd;
    font-size: 15px;
    font-family: $helvetica;
    text-decoration: none;
    border-radius: 7px;
    border: solid 1px #eae5e1;
    background-color: #fff;

    @include mq(330, 'max-width') {
      padding: 0 7px;
    }

    &:first-child {
      margin-right: 7px;
    }
  }

  &__label {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    width: 100%;
  }
}
