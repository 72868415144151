$title-color: #2f1f19;
$total-color: #98918a;
$margin-top: get-vw(55px);

.search {
  &-tools {
    position: relative;
    margin-top: 17.1875vw;
    padding-bottom: 70px;

    @include mq(480) {
      $margin-top: 55px;

      margin-top: $margin-top;
    }
  }

  &-page__loader {
    margin-top: 200px !important;
  }

  &-result {
    position: relative;
    padding: 20px 15px 15px;

    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      left: 15px;
      right: 15px;
      bottom: 0;
      height: 1px;
      background-color: #f7f6f6;
    }

    &__text {
      margin: 0 0 5px;
      color: $title-color;
      font-size: 15px;
      line-height: 1.3;
      font-weight: 500;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    &__total {
      color: $total-color;
      font-size: 15px;
      font-weight: normal;
    }
  }

  &-toolbar {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    background-color: $colour-grey-desert-storm;
    transition: top 0.1s linear;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #f7f6f6;
      transform: translateX(-50%);
    }
  }
}
