$background-color: #fff;

.layout {
  background-color: $background-color;

  &__content {
    padding: 15px;
    background: #fff;

    &-residences {
      padding: 20px 15px 15px;
    }
  }

  &__space {
    padding: 0;
  }

  &__current-item {
    background-color: $background-color;
    padding: 0 15px;
  }
}
