$colour-neon-blue: #4264fd;
$colour-lavender: #dfe5f9;
$colour-lavender2: #f0f3fc;
$colour-white-smoke: #ededed;
$colour-cocoa-brown: #2f1f19;
$colour-cocoa-brown2: #2e1e18;
$colour-heathered-grey: #98918a;
$colour-white: #fff;
$colour-wild-sand: #f4f2ef;
$colour-wild-sand-opacity: rgba(244, 242, 239, 0.7);
$colour-whisper: #fdfcfc;
$colour-dark-tan: #946952;
$colour-grey-black-squeeze: #fafaf9;
$colour-grey-alabaster: #faf9f7;
$colour-grey-pale-slate: #c5c1bc;
$colour-grey-desert-storm: #f6f4f1;
$colour-grey-bon-jour: #ddd6cf;
$colour-grey-wild-sand: #eae5e1;
$colour-yellow: #ffdd2c;
