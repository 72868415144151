$color: #2f1f19;

.services-section {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;

  &__item {
    display: flex;
    height: 28px;
    padding: 5px 10px;
    align-items: center;
    gap: 5px;
    border-radius: 7px;
    background-color: #f8f8f8;

    i {
      align-self: center;
    }

    span {
      color: $color;
      font-size: 15px;
      font-family: $helvetica;
    }
  }
}
