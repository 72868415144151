.show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: get-vw(45px) get-vw(44px);
  font-size: get-vw(12px);
  color: #fff;
  text-decoration: none;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.53);
  transition: opacity 0.7s;
  box-sizing: border-box;

  @include mq(480) {
    padding: 45px 44px;
    font-size: 12px;
  }
}

.image-link {
  position: relative;
  display: flex;
  z-index: 9;
  width: 100%;
  height: 100%;
}

.swiper-container {
  margin: 0 auto;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */

.swiper-container-autoheight {
  height: auto;

  .swiper-slide {
    height: auto;
  }

  .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
}

.swiper-container-wp8-horizontal {
  touch-action: pan-y;

  > .swiper-wrapper {
    touch-action: pan-y;
  }
}

.swiper-container-wp8-vertical {
  touch-action: pan-x;

  > .swiper-wrapper {
    touch-action: pan-x;
  }
}

.swiper-button-prev,
.swiper-button-next {
  $size: get-vw(21px);
  position: absolute;
  top: 50%;
  width: $size;
  height: $size;
  margin-top: -($size / 2);
  z-index: 11;
  outline: none;
  border: 0;
  border-radius: 50%;
  background-size: cover;
  background-color: transparent;

  @include mq(480) {
    $size: 24px;
    width: $size;
    height: $size;
    margin-top: -($size / 2);
  }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url('/static/images/item-slider-arrow-left.svg');
  left: get-vw(10px);
  right: auto;

  @include mq(480) {
    left: 10px;
  }
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url('/static/images/item-slider-arrow-right.svg');
  right: get-vw(10px);
  left: auto;

  @include mq(480) {
    right: 10px;
  }
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  touch-action: none;
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-container-fade {
  &.swiper-container-free-mode .swiper-slide {
    transition-timing-function: ease-out;
  }

  .swiper-slide {
    pointer-events: none;
    transition-property: opacity;

    .swiper-slide {
      pointer-events: none;
    }
  }

  .swiper-slide-active {
    pointer-events: auto;

    .swiper-slide-active {
      pointer-events: auto;
    }
  }
}

.swiper-container-cube {
  overflow: visible;

  .swiper-slide {
    pointer-events: none;
    backface-visibility: hidden;
    z-index: 1;
    visibility: hidden;
    transform-origin: 0 0;
    width: 100%;
    height: 100%;

    .swiper-slide {
      pointer-events: none;
    }
  }

  &.swiper-container-rtl .swiper-slide {
    transform-origin: 100% 0;
  }

  .swiper-slide-active {
    pointer-events: auto;
    visibility: visible;

    .swiper-slide-active {
      pointer-events: auto;
    }
  }

  .swiper-slide-next,
  .swiper-slide-prev,
  .swiper-slide-next + .swiper-slide {
    pointer-events: auto;
    visibility: visible;
  }

  .swiper-slide-shadow-top,
  .swiper-slide-shadow-bottom,
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    z-index: 0;
    backface-visibility: hidden;
  }

  .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    filter: blur(50px);
    z-index: 0;
  }
}

.swiper-container-flip {
  overflow: visible;

  .swiper-slide {
    pointer-events: none;
    backface-visibility: hidden;
    z-index: 1;

    .swiper-slide {
      pointer-events: none;
    }
  }

  .swiper-slide-active {
    pointer-events: auto;

    .swiper-slide-active {
      pointer-events: auto;
    }
  }

  .swiper-slide-shadow-top,
  .swiper-slide-shadow-bottom,
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    z-index: 0;
    backface-visibility: hidden;
  }
}

.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  // stylelint-disable-next-line
  -ms-perspective: 1200px;
}
