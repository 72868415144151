.agencies {
  &-i {
    display: block;
    width: calc(100% - 20px);
    height: 105px;
    margin: 0 10px 10px;
    padding: 5px;
    padding-left: 116px;
    box-shadow: 0 0 3px 0 rgba(255, 255, 255, 0.84);
    position: relative;
    box-sizing: border-box;
    background-color: #fff;
    text-decoration: none;

    &--title {
      overflow: hidden;
      text-overflow: ellipsis;
      color: #3b3a3a;
      white-space: nowrap;
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: bold;
    }

    &--offers-count {
      font-size: 13px;
      margin-bottom: 5px;
      color: #9b9b9b;
    }

    &--logo {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 105px;
      height: 105px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      border-right: 1px solid #e5e5e5;

      img {
        width: 100%;
        height: auto;
      }
    }

    &--description {
      @include multiline-ellipsis(12px, 1.2, 3);
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: #4a4a4a;
    }
  }
}
