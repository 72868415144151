.breadcrumbs {
  $this: &;
  width: 100%;
  overflow: hidden;

  &__white {
    background-color: #fff;
    padding: 3.125vw 0 10px;
  }

  &-list {
    padding: 15px 10px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    font-size: 0;
  }

  &-i {
    position: relative;
    display: inline-block;
    margin-right: 22px;
    color: #747474;
    font-size: 14px;

    a {
      color: #9b9b9b;
      text-decoration: none;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -14px;
        width: 6px;
        height: 10px;
        margin-top: -2px;
        background-image: url('/static/images/breadcrumbs-icon.svg');
      }
    }

    p {
      color: #2e2e2e;
      margin: 0;
    }
  }

  &-residences {
    background: #f6f4f1;
    border: 1px solid #eae5e1;

    #{$this}-i {
      a,
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: #98918a;
      }

      a::after {
        width: 3px;
        height: 3px;
        background: #98918a;
        background-image: none;
        border-radius: 50%;
      }
    }
  }
}
