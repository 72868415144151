.header-section {
  position: relative;
  margin: 3.125vw 5px 8px;
  padding: 10px 10px 0;
  border-radius: 2px;
  background-color: #d3e9ff;
  box-shadow: 0 1px 3px 0 #dbd8d8;
  color: #000;
  font-size: 15px;
  font-family: $helvetica;

  &__logo {
    position: absolute;
    top: 14px;
    left: 10px;
    border-radius: 3px;
    overflow: hidden;

    img {
      display: block;
      width: 90px;
      height: 90px;
    }
  }

  &__info {
    height: 96px;
    padding-left: 99px;

    &-row {
      display: flex;
    }

    &-title {
      max-height: 45px;
      font-size: 18px;
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #2b2b2b;
      font-weight: bold;
      margin: 4px 0;
      display: -webkit-box; // stylelint-disable-line  value-no-vendor-prefix
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
    }

    &-views {
      background-image: url('/static/images/eye-icon.svg');
      font-family: 'Source Sans Pro', Arial, sans-serif;
      font-size: 15px;
      margin-bottom: 4px;
      font-weight: 600;
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 21px;
      color: #4a4a4a;
    }

    &-flats {
      margin-right: 15px;
      font-size: 15px;
      color: #919191;
      white-space: nowrap;
    }

    &-metro {
      @include ellipsis;
      font-size: 15px;
      color: #238df7;
    }
  }

  &__description {
    width: 100%;
    padding-bottom: 20px;
    font-size: 15px;
    line-height: 1.2;
  }
}
