.search-card {
  min-width: 290px;
  margin-right: 10px;
  padding: 20px 15px 15px;
  border-radius: 3px;
  background-color: #fff;
  background-position: 0 50%;
  background-size: cover;
  box-sizing: border-box;
  // stylelint-disable property-no-vendor-prefix
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  // stylelint-enable property-no-vendor-prefix

  &:last-child {
    margin-right: 0;
  }

  &__title {
    margin: 0 0 15px;
    font-size: 15px;
    font-weight: 500;
    color: #2f1f19;
    font-family: $helvetica;
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;

    &_column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &:last-child {
        margin-left: 8px;
      }
    }
  }

  &__link {
    margin-bottom: 15px;
    padding: 9px 15px;
    font-size: 15px;
    color: #2f1f19;
    letter-spacing: -0.38px;
    text-decoration: none;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(168, 156, 144, 0.15);
    background-color: #fff;
    white-space: nowrap;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-list {
    display: inline-flex;
    margin: 20px 15px;
  }
}
