$color: #ebedf0;
$animation-duration: 1s;
$animation-fill-mode: forwards;
$animation-iteration-count: infinite;
$loading-gradient: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
$background-size: 1200px 104px;

.placeholder {
  &-row {
    height: 10px;
    background-color: $color;

    &--mt--10 {
      margin-top: 10px;
    }

    &--mb--20 {
      margin-bottom: 20px;
    }
  }

  &-header {
    padding: 2.1875vw 4.6875vw;
    height: 17.1875vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__burger {
      margin-right: 3.2vw;
      height: 8.1875vw;
      width: 7.6875vw;
    }

    &__logo {
      height: 9.1875vw;
      width: 31.5625vw;
    }

    &__button {
      height: 10.9375vw;
      width: 36vw;
      margin-left: auto;
    }
  }

  &-footer {
    padding: 0 10px;

    &__top {
      height: 140px;
      display: flex;
      flex-wrap: wrap;
      padding: 15px 0 20px;
      border-bottom: 1px solid #ebedf0;

      &-title {
        width: 50%;
      }

      &-contacts {
        width: 75%;
      }
    }

    &_bottom {
      height: 154px;
      padding: 20px 0 25px;

      &_-agreement {
        width: 50%;
      }

      &-map {
        width: 20%;
      }

      &-advertising {
        width: 30%;
      }

      &-full {
        width: 25%;
      }

      &-copyright {
        width: 45%;
      }
    }

    &__col {
      &--1 {
        flex: 0 0 100%;
      }

      &--2 {
        flex: 0 0 50%;
      }
    }
  }

  &-ql {
    &__header {
      height: 45px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-row {
        width: 20%;
      }
    }

    &__card {
      width: 290px;
      height: 200px;
      margin-right: 5%;
      border-radius: 5px;
      background-color: $color;

      &-list {
        overflow: hidden;
        display: inline-flex;
        margin: 20px 15px;
      }
    }
  }

  &-st {
    &__top {
      height: 76px;
      padding: 20px 15px 15px;

      &-h1 {
        width: 45%;
      }

      &-count {
        width: 35%;
      }
    }

    &__bottom {
      display: flex;
      height: 70px;
      padding: 20px 15px 15px;
      justify-content: space-between;
      align-items: center;

      &-button {
        width: 100px;
        height: 40px;
      }

      &-select {
        width: 40px;
      }
    }
  }

  &-section {
    margin-bottom: 10px;
    padding: 0 10px;

    &__header {
      display: flex;
      justify-content: space-between;
      height: 48px;
      padding: 14px 0;

      &-title {
        width: 35%;
        height: 20px;
      }

      &-more {
        width: 10%;
        margin-top: 5px;
      }
    }

    &__card {
      &-list {
        margin-top: 5px;
        display: flex;
        flex-flow: wrap;

        &-column {
          flex-flow: column;
        }
      }
    }
  }

  &-card {
    width: 48.5%;
    margin-right: 2.9%;
    height: 200px;

    &:nth-of-type(2n + 2) {
      margin: 0;
    }

    &__img {
      height: 120px;
      background-color: $color;
    }

    &__city {
      width: 40%;
    }

    &__rooms {
      width: 80%;
    }

    &__date {
      width: 50%;
    }
  }

  &-ac {
    position: relative;
    width: calc(100% - 20px);
    height: 105px;
    margin-bottom: 10px;
    padding-left: 116px;

    &__logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 105px;
      height: 105px;
    }

    &__title {
      width: 45%;
    }

    &__counts {
      width: 60%;
    }

    &__descr {
      width: 95%;
    }
  }

  &-bc {
    position: relative;
    height: 158px;
    margin: 0 0 10px;
    padding-left: 170px;

    &__logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
      height: 158px;
    }

    &__title {
      width: 50%;
    }

    &__area {
      width: 40%;
    }

    &__location {
      width: 60%;
    }

    &__metro {
      width: 35%;
    }

    &__class {
      width: 40%;
    }
  }

  &-rc {
    width: 300px;
    margin: auto;
    margin-bottom: 10px;

    &:first-child {
      margin-top: 10px;
    }

    &__img {
      height: 150px;
      background-color: $color;
    }

    &__title {
      width: 50%;
    }

    &__developer {
      width: 45%;
    }

    &__location {
      width: 70%;
    }

    &__metro {
      width: 40%;
    }

    &__deadline {
      width: 30%;
    }
  }

  &-breadcrumbs {
    position: relative;
    height: 65px;
    padding: 3.125vw 0 10px;

    .placeholder-row {
      position: absolute;
      left: 10px;
      top: 50%;
      width: 50%;
      transform: translateY(-50%);
    }
  }

  &-menu {
    .placeholder-row {
      height: 5vw;
    }

    &__bookmarks {
      padding: 5vw 6.25vw 4.6875vw 4.6875vw;
      border-top: 1px solid #414141;
      border-bottom: 1px solid #414141;

      .placeholder-row {
        width: 150px;
      }
    }

    &__list {
      padding: 5vw 0;
      border-top: 1px solid #414141;
      border-bottom: 1px solid #414141;

      &-i {
        padding: 3.125vw 4.6875vw;

        &__buy {
          width: 85px;
        }

        &__rent {
          width: 65px;
        }

        &__agencies {
          width: 100px;
        }

        &__residencies {
          width: 175px;
        }

        &__bc {
          width: 150px;
        }
      }
    }

    &__footer {
      padding: 5vw 0;

      &-top_i {
        padding: 3.125vw 4.6875vw;
        width: 200px;
      }

      &-bottom {
        margin-top: 18.75vw;

        &_i {
          padding: 2.34375vw 4.6875vw;
          // stylelint-disable max-nesting-depth
          &__title {
            width: 100px;
            height: 4vw;
          }

          &__tel {
            width: 175px;
          }

          &__email {
            width: 150px;
          }
        }
      }
    }
  }

  &-auth {
    display: flex;
    align-items: center;
    height: 21.25vw;
    padding-left: 4.6875vw;
    background-color: #4d4d4d;

    .placeholder-row {
      width: 100px;
      height: 5vw;
    }
  }

  &-info-label {
    position: relative;
    padding: 10px;

    .placeholder-row {
      margin-top: 10px;
    }

    &__img {
      width: 90px;
      height: 90px;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    &__info {
      height: 95px;
      padding-left: 100px;
    }
  }

  &-gallery {
    &__large {
      width: 100%;
      height: 268px;
      margin-top: 10px;
      background-color: $color;
    }

    &__thumb {
      width: 330px;
      height: 268px;
      margin-right: 2%;
      background-color: $color;

      &-list {
        overflow: hidden;
        display: inline-flex;
        margin: 5px 10px 30px;
      }
    }
  }
}

.animation {
  animation-duration: $animation-duration;
  animation-fill-mode: $animation-fill-mode;
  animation-iteration-count: $animation-iteration-count;
  animation-name: placeload;
  animation-timing-function: linear;
  background: $loading-gradient;
  background-size: $background-size;
}

@keyframes placeload {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
