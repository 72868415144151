.cur-item-desc {
  margin-bottom: 20px;

  &__title {
    margin-bottom: 20px;
    font-size: 18px;
    font-family: $helvetica;
    font-weight: 500;
    color: #2f1f19;
  }
}
