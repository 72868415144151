.search {
  font-family: $helvetica;

  &--full {
    border-top: 1px solid $colour-wild-sand;
  }

  &-form {
    padding: 10px 0 20px;

    &--full {
      padding: 10px 0 65px;
    }

    &__city {
      @include arrow(8px, $colour-neon-blue);
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      padding: 0 $f-side-padding;
      font-size: 15px;
      line-height: 37px;

      &_label {
        margin-right: 4px;
        color: $colour-cocoa-brown;
      }

      &_name {
        color: $colour-neon-blue;
        font-weight: 500;
        outline: none;
        text-decoration: underline;
      }

      &_loader {
        margin: 0;
        text-decoration: underline;

        span {
          width: 15px;
          height: 15px;
          margin: 0;
        }
      }
    }

    &__locations {
      margin-bottom: 15px;
      border-radius: $f-border-radius;
      border: 1px solid $colour-grey-wild-sand;
      background-color: $colour-white;
      color: $colour-cocoa-brown;

      &_row {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        padding: 0 28px 0 10px;
        outline: none;

        @include arrow(10px, $colour-heathered-grey, 2px) {
          margin-left: auto;
          margin-right: -18px;
        }
      }

      &_placeholder {
        font-size: 15px;
        color: $colour-heathered-grey;
      }

      &_names {
        @include ellipsis;
        color: $colour-cocoa-brown;
        width: 100%;
      }
    }

    &__leased,
    &__has-repair {
      .f-input {
        width: 50%;

        &:first-child .f-input__label {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &:last-child .f-input__label {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          margin-left: -1px;
        }
      }
    }

    &__has-repair {
      .f-input {
        &:first-child .f-input__label {
          margin-left: 1px;
        }
      }
    }

    &__category,
    &__rooms {
      .f-input {
        &__label {
          border-radius: 20px;
        }

        + .f-input {
          margin-left: 5px;
        }
      }
    }

    &__category {
      .f-input:last-child {
        padding-right: $f-side-padding;
      }
    }

    &__rooms {
      .f-input {
        min-width: 48px;

        @include mq(359, 'max-width') {
          min-width: 44px;
        }

        &:last-child {
          flex-grow: 1;
        }
      }
    }

    &__button {
      $button: &;
      width: calc(100% - 30px);
      height: 45px;
      line-height: 45px;
      border-radius: 7px;

      &--sticky {
        // stylelint-disable-next-line
        position: -webkit-sticky; // Safari
        position: sticky;
      }

      &--fixed {
        position: fixed;
      }

      &--submit {
        display: block;
        bottom: calc(env(safe-area-inset-bottom) + 10px);
        margin: 0 15px;
        flex-grow: 1;
        font-size: 16px;
        text-align: center;
        color: $colour-cocoa-brown;
        background-color: $colour-yellow;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);

        + #{$button}--filters {
          margin-left: 5px;
        }
      }

      &--filters {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $colour-grey-wild-sand;
        margin: 0 15px 10px;
        font-size: 16px;
        line-height: 1.2;
        color: $colour-neon-blue;
        cursor: pointer;
        background-color: $colour-white;
        outline: none;
      }
    }

    &__group {
      padding: 0 $f-side-padding;

      + #{&} {
        .search--full & {
          padding-top: 15px;
          border-top: 1px solid $colour-white-smoke;
        }
      }

      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    input,
    button {
      font-family: $helvetica;
    }
  }
}
