$color: #2f1f19;

.cur-item-head {
  padding: 0 15px;
  height: 60px;

  &__container {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__left-block {
    width: 57px;

    svg {
      transform: rotate(180deg);
    }
  }

  &__right-block {
    width: 53px;
  }
}
