.bottom-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-family: $helvetica;

  &__title {
    width: 50%;
    color: #98918a;
    font-size: 15px;
  }

  &__value {
    color: #2f1f19;
    font-size: 15px;
  }

  p {
    margin: 0 0 5px;
  }
}
