.search-sort {
  select {
    display: inline-block;
    padding-right: 5px;
    text-align-last: right;
    direction: rtl;
    color: #4264fd;
    font-size: 15px;
    outline: none;
    border: 0;
    appearance: none;
    background: transparent;
  }
}
