.calc-summary {
  font-family: $helvetica;

  &__message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 11px 0;

    span {
      display: block;
      color: #09101d;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__cv {
    border-radius: 7px;
    background-color: #a4243b;
    padding: 20px 15px;
    color: #fff;
    margin-bottom: 10px;

    &-title {
      font-size: 18px;
      font-weight: 500;
    }

    &-persentages {
      display: flex;
      gap: 20px;
      padding: 25px 0 20px;
      color: #e2e2e2;
      font-size: 16px;
      border-bottom: 1px solid #eae5e1;

      &-value {
        margin-top: 5px;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }

      span {
        display: block;
      }
    }

    &-result {
      margin-top: 20px;
      color: #e2e2e2;
      font-size: 16px;

      &-sum {
        margin-top: 5px;
        font-size: 30px;
        font-weight: 500;
        color: #fff;
      }

      span {
        display: block;
      }
    }
  }
}
