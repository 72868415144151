.calc-form {
  font-family: $helvetica;

  &__block {
    margin-bottom: 20px;
  }

  &__group {
    position: relative;
    margin-bottom: 20px;

    &-label {
      position: relative;
      color: #09101d;
      font-size: 16px;
      opacity: 0.8;
      font-weight: 500;
      margin-bottom: 10px;
      display: inline-block;

      &--required::before {
        position: absolute;
        content: '*';
        opacity: 0.8;
        color: #a4243b;
        font-size: 16px;
        right: -6px;
      }
    }

    &-inpt {
      width: 100%;
      position: relative;

      input {
        width: 100%;
        height: 55px;
        padding: 0 15px;
        color: #2e3131;
        border-radius: 7px;
        border: 1px solid #8a9199;
        font-size: 16px;
      }

      input:focus {
        outline: none;
        border: 1px solid #8a9199;
      }

      input::placeholder {
        width: 70%;
        color: #2e3131;
        font-size: 16px;
      }

      &--error {
        position: absolute;
        display: flex;
        align-items: center;
        right: 15px;
        left: 15px;
        top: 2px;
        background-color: #fff;
        height: 52px;
        color: #a4243b;
        font-size: 16px;
        font-weight: 400;
      }

      &--unnecessary {
        color: #09101d;
        font-size: 15px;
        font-weight: 500;
        display: block;
        margin-top: 10px;
      }
    }
  }
}
