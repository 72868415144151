.white-header {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0 15px;
  border-bottom: 1px solid #eae5e1;

  &--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;

    ~ .fallback-view,
    ~ .placeholder-st {
      margin-top: get-vw(55px);
    }
  }

  &--main {
    justify-content: space-between;
  }

  &__title {
    color: #2f1f19;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  &__webview-btn {
    height: 38px;
    width: 38px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      display: block;
      flex: 1;
    }
  }
}
