.search-input {
  flex: 1 1;
  width: calc(100% - 10px);
  height: 38px;
  padding: 10px 0 10px 35px;
  font-size: 15px;
  box-sizing: border-box;
  font-family: $helvetica;
  outline: none;
  border-radius: 7px;
  border: 0;
  background-color: transparent;
  appearance: none;

  &-container {
    display: flex;
    flex: 1 0;
    align-items: center;
    border-radius: 7px;
    border: 1px solid $colour-grey-wild-sand;
    background-color: $colour-white;
    font-family: $helvetica;
    background-size: 15px 15px;
    background-position: 10px 50%;
    background-repeat: no-repeat;
    background-image: url('/static/images/search/icons/icon-search.svg');
    box-sizing: border-box;
  }

  &__reset-btn {
    width: 32px;
    height: 38px;
    padding: 10px;
    background-image: url('/static/images/search/icons/icon-reset.svg');
    background-size: 12px 12px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    box-sizing: border-box;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    appearance: none;
  }

  &::placeholder {
    font-family: $helvetica;
    font-size: 15px;
    color: $colour-heathered-grey;
  }
}
