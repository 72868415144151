$default-color: #979797;
$active-color: #7ed321;

.popular-requests-section {
  box-sizing: border-box;
  padding: 0 10px 20px;
  background-color: #fff;

  .pr-title {
    color: #c71b24;
    padding: 0;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  ul.popular-requests {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      float: left;
      height: 30px;
      line-height: 30px;
      background: #fff;
      border: 1px solid $default-color;
      border-radius: 15px;
      margin: 10px 7px 0 0;
      padding: 0;

      &:hover {
        border-color: $active-color;
      }
    }

    a {
      color: $default-color;
      font-size: 13px;
      padding: 0 8px;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
    }
  }

  &.pr-mobile {
    padding: 10px 10px 20px;

    h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    ul.popular-requests {
      li {
        margin: 10px 7px 0 0;
      }

      a {
        font-size: 13px;
        padding: 0 8px;
      }
    }
  }
}
